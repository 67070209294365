<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.id || index">
      <td>{{ item.name }}</td>
      <td>{{ item.email }}</td>
      <td>{{ item.role }}</td>
      <td>
        <v-switch
          v-model="item.active"
          @change="handleChangeSwitch(item.id, item.active)"
        ></v-switch>
      </td>
      <td class="text-center">
        <v-btn
          small
          color="primary"
          :elevation="0"
          @click="handleClickEdit(item.id)"
        >
          Editar
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
// Services
import { updatePanelUser } from '@/services/panelUser'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'PanelUsersListItem',
  mixins: [uiMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    /**
     * Handle change on switch button
     */
    async handleChangeSwitch(id, active) {
      if (id) {
        await updatePanelUser({
          id,
          active
        })
      }
    },
    /**
     * Handle click on edit button
     */
    handleClickEdit(id) {
      if (id) {
        this.routerPushTo({
          path: `/panel-users-form/${id}`
        })
      }
    }
  }
}
</script>
