<template>
  <PanelSection
    title="Usuarios del panel"
    :hide-action-buttons="false"
    @onClickAddButton="handleClickAddButton"
  >
    <div class="panel-users-list">
      <v-container>
        <CustomDataTable
          :headers="dataTable.headers"
          :items="dataTable.items"
          :items-per-page="dataTable.itemsPerPage"
          :loading="dataTable.loading"
          :page="dataTable.page"
          :search-by="dataTable.searchBy"
          @onPrevPage="handlePrevPage"
          @onNextPage="handleNextPage"
          @onSearch="handleSearch"
          @onChangeItemsPerPage="handleChangeItemsPerPage"
        >
          <template slot-scope="{ items }">
            <PanelUsersListItem :items="items" />
          </template>
        </CustomDataTable>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Components
import PanelSection from '@/components/ui/PanelSection'
import CustomDataTable from '@/components/ui/CustomDataTable'
import PanelUsersListItem from '@/components/elements/panelUsers/PanelUsersListItem'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getAllPanelUsers } from '@/services/panelUser'

export default {
  name: 'PanelUsersList',
  components: { PanelSection, CustomDataTable, PanelUsersListItem },
  mixins: [uiMixin],
  data() {
    return {
      // Data table
      dataTable: {
        headers: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name'
          },
          { text: 'E-mail', value: 'email' },
          { text: 'Rol', value: 'role' },
          { text: 'Activo', value: 'active' },
          { text: 'Acciones', align: 'center' }
        ],
        items: [],
        itemsPerPage: 20,
        loading: true,
        page: 1,
        searchBy: [
          {
            id: 'nombre',
            label: 'Nombre'
          },
          {
            id: 'email',
            label: 'E-mail'
          }
        ]
      }
    }
  },
  async mounted() {
    await this.setDataTableItems()
  },
  methods: {
    /**
     * Handle click on Add button
     */
    handleClickAddButton() {
      this.routerPushTo({
        path: '/panel-users-form'
      })
    },
    /**
     * Handle click on previous page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handlePrevPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle click on next page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleNextPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.page = paginationOptions.page || 1
    },
    /**
     * Handle when change number of items per page
     *
     * @param {object} paginationOptions - pagination options
     */
    async handleChangeItemsPerPage(paginationOptions) {
      await this.setDataTableItems(paginationOptions)
      this.dataTable.itemsPerPage = paginationOptions.limit || 20
      this.dataTable.page = 1
    },
    /**
     * Handle make a search
     *
     * @param {object} searchOptions - pagination options
     */
    async handleSearch(searchOptions) {
      const currentSearchOptions = searchOptions.query
        ? {
            order: {
              field: searchOptions.queryField,
              direction: 'asc'
            },
            ...searchOptions
          }
        : null
      await this.setDataTableItems(currentSearchOptions)
      this.dataTable.page = 1
    },
    /**
     * Set items to data table
     *
     * @param {object} paginationOptions - pagination options
     */
    async setDataTableItems(paginationOptions = null) {
      const orderBy = {
        field: 'email',
        direction: 'asc'
      }
      const currentPaginationOptions =
        paginationOptions === null
          ? { order: orderBy }
          : { ...paginationOptions, order: orderBy }
      this.dataTable.loading = true
      this.dataTable.items = await getAllPanelUsers(currentPaginationOptions)
      this.dataTable.loading = false
    }
  }
}
</script>
